import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDate } from './HelperUtils';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';


interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  onClickShare : ()=>void;
  event : any
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px 10px 40px 10px',
    overflow: 'hidden',
    background: "#F3F3F3"
  },
  '& .modal-parent': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: 'border-box',
    padding: "32px",
    backgroundColor: "#F3F3F3",
    overflowY: "scroll",
    overflowX : "hidden"
  },
  '& .modal-content': {
    width: "560px",
    boxSizing: 'border-box',
  },
  '& .eventDetailHeading': {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "12px",
    boxSizing: 'border-box',
  },
  '& .eventDetailText': {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '10px',
    textAlign: 'left',
    marginBottom: "13px",
    marginTop: "13px",
  },
  '& .ageRestriction': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  "& .eventTimeCard": {
    width: '549px',
    height: '80px',
    borderRadius: '20px',
    boxShadow: '0px 4px 4px 0px #0000001A',
    margin: "18px 0",
    background: "white",
    display: "flex"
  },
  "& .eventOrganizerHeading": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '15px',
    marginBottom: "6px"
  },
  "& .eventOrganizerSubHeading": {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '10px',
    color: "#818384"
  },
  "& .eventDetails": {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: "justify",
    whiteSpace: 'pre-wrap',
    color: "#343434"
  }
}));

const StyledHr = styled('hr')({
  border: '1px solid #E2E8F0',
  marginTop: '19px',
  marginBottom: '16px',
});


const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '221px',
  overflow: 'hidden',
  borderRadius: '10px',
  boxSizing: 'border-box',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'top',
});

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  color: theme.palette.common.white,
  boxSizing: 'border-box',
  textAlign: 'center',
  background : "rgba(0,0,0,0.3)"
}));

const EventHeading = styled('div')(({ theme }) => ({
  width: "100%",
  display: 'flex',
  flexDirection: 'column',
  alignItems: "flex-start",
  paddingLeft: "24px",
  paddingBottom: "20px",
  "& .eventName": {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '10px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .categories": {
    display: "flex",
    gap: "15px",
  }
}));

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '14px',
  right: '19px',
  color: '#fff',
  height : "41px",
  width : "41px",
  borderRadius : "50%",
  border : "3px solid #E2E8F0"
});

const StyledCategory = styled('div')(({ theme }) => ({
  height: '28px',
  width : "95px",
  border: '1px solid #F3F3F3',
  borderRadius  :"100px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "8px",
  marginBottom: "12px",
  background: "#3434344D",
  boxSizing : "border-box",
  "& .categoryBtnTxt": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '10px',
  }
}));

const GradientButton = styled(Button)({
  width: '117px',
  height: '34px',
  borderRadius: '100px',
  background: 'linear-gradient(94.85deg, #BC3081 5.19%, #D62339 23.82%, #FE9612 36.03%, #FED576 54.68%, #96B999 70.72%, #3C8D9E 82.89%, #045E81 96.01%)',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '10px',
  color: "black"
});

const ActionButtons = styled('div')(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "45px",
  gap: "12px",
  marginTop: "60px",
  "& .addToCalendarBtn": {
    width: "90%",
    backgroundColor: "#F47133",
    borderRadius: '100px 50px 50px 100px',
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    cursor : "pointer"
  },
  "& .shareBtn": {
    width: "45px",
    backgroundColor: "#D62339",
    borderRadius: "10px",
    display: "flex",
    cursor : "pointer"
  },
  "& .shareIcon": {
    color: "white",
    fontSize: "24px",
    margin: "auto"
  },
  "& .calendarIconContainer": {
    width: "38px",
    height: "38px",
    AspectRatio:1,
    borderRadius: "50%",
    background: "#F3F3F3",
    display: "flex"
  },
  "& .calendarIcon": {
    color: "#F47133",
    fontSize: "20px",
    margin: "auto"
  },
  "& .addToCalTextContainer": {
    display: "flex",
    width: "100%",
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '10px',
    justifyContent: "center",
    color: "white"
  }
}));

const StyledEventTimeItem = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: "12px",
  position: 'relative',
  flex: 1,
  '& .eventTimeHeading': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '10px',
    color: "#343434",
    marginBottom: "6px"
  },
  '& .eventTimeContent': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    color: "#818384",
    marginRight : "5px"
  }
});


const category = (category: any) => {
  return (
    <StyledCategory>
      <img src={require("./music.png")} />
      <Typography className='categoryBtnTxt' >{category}</Typography>
    </StyledCategory>
  )
}

const eventAge = (age: string): string => {
  switch (age) {
    case 'all_ages':
      return 'All Ages';
    case 'over_18':
      return 'Over 18';
    case 'over_21':
      return 'Over 21';
    default:
      return 'All Ages';
  }
};


interface EventTimeItemProps {
  icon: "location" | "date" | "time"
  title: string
  content: string
  showDivider?: boolean
}

const EventTimeItem: React.FC<EventTimeItemProps> = ({ icon, title, content, showDivider = true }) => {
  const icons = {
    location: PlaceOutlinedIcon,
    date: CalendarMonthIcon,
    time: AccessTimeIcon
  }

  const Icon = icons[icon]

  return (
    <StyledEventTimeItem>
      <Icon sx={{ color: '#045E81', fontSize: 20 }} />
      <Box>
        <Typography className='eventTimeHeading'>
          {title}
        </Typography>
        <Typography className='eventTimeContent'>
          {content}
        </Typography>
      </Box>
      {showDivider && (
        <Divider orientation="vertical" flexItem sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', height: '48px', marginRight : "7px" }} />
      )}
    </StyledEventTimeItem>
  )
}


const EventPopup: React.FC<CustomDialogProps> = ({ open, onClose, onClickShare, event }) => {
  console.log("event", event);
  const attributes = event?.attributes || {};
  return (
    <BootstrapDialog onClose={onClose} open={open}>
      {attributes.title &&
      <Box className='modal-parent'>
        <Box className='modal-content'>
          <ImageContainer>
            <StyledImage src={attributes.event_posters[0].url} alt="event_image" />
            <Overlay>
              <EventHeading>
                <Box className="categories">
                  {attributes.sub_categories.data.map((item:any, index:any)=>{
                    return category(item.attributes.name)
                })}
                </Box>
                <Typography className='eventName' variant="h6">{attributes.title}</Typography>
              </EventHeading>
              <CloseButton onClick={onClose}>
                <CloseRoundedIcon />
              </CloseButton>
            </Overlay>
          </ImageContainer>
          <Box style={{paddingLeft : "10px"}}>
          <Box className="eventDetailHeading">
            <Box >
              <Typography className="eventDetailText">About the Event </Typography>
              <Typography className='ageRestriction'>{eventAge(attributes.age)}
                <span style={{color : attributes.categoryClr}}> | Category</span>
              </Typography>
            </Box>
            <GradientButton>
              ${attributes.cost}
            </GradientButton>
          </Box>
          <Box className="eventTimeCard">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', p: 2, boxSizing : "border-box" }}>
              <Box sx={{ flex: '0 0 45%' }}>
                <EventTimeItem
                  icon="location"
                  title="Location"
                  content={attributes.location?.data?.attributes?.venue_name + 
                    attributes.location?.data?.attributes?.address
                  }
                />
              </Box>
              <Box sx={{ flex: '0 0 30%' }}>
                <EventTimeItem
                  icon="date"
                  title="Date"
                  content={attributes.start_date && formatDate(attributes.start_date)}
                />
              </Box>
              <Box sx={{ flex: '0 0 25%' }}>
                <EventTimeItem
                  icon="time"
                  title="Time"
                  content={
                    attributes.start_time && attributes.end_time
                      ? `${event.attributes.start_time} -  ${event.attributes.end_time}`
                      : "Time not available"
                  }    
                  showDivider={false}
                />
              </Box>
            </Box>
          </Box>
          <Typography className='eventOrganizerHeading'>
            {attributes.event_organizer.data.attributes.name}
          </Typography>
          <Typography className='eventOrganizerSubHeading'>
            Event Organizer
          </Typography>
          <StyledHr />
          <Typography className='eventDetails'>
           {attributes.description}
          </Typography>
          <ActionButtons>
            <Box className='addToCalendarBtn'>
              <Box className='calendarIconContainer'>
                <CalendarTodayIcon className='calendarIcon' />
              </Box>
              <Box className="addToCalTextContainer">
                <Typography>
                  Add To Calendar
                </Typography>
              </Box>
            </Box>
            <Box className='shareBtn' onClick={onClickShare}>
              <ShareIcon className='shareIcon' />
            </Box>
          </ActionButtons>
          </Box>
        </Box>
      </Box>
      }            
    </BootstrapDialog>
  );
};

export default EventPopup;
