import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Card, CardContent, CardMedia, Typography, Grid,Box , Divider,Container
} from '@mui/material';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { landingBgImage, arrowRight, spark60, phone24, email24, insta24, logo } from "./assets";
import EventsPopUp from "../../../components/src/EventsPopup";
import { ShareEventModal } from "../../../components/src/ShareEventModal";
// Customizable Area End
import LandingPageController, {
    Props
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  imgResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };

  cardResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 0
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
      items: 3, 
      slidesToSlide: 1 
    },
    horPhone: {
      breakpoint: { max: 900, min: 600 },
      items: 2, 
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };

  displayEvent = (data:any) =>{
    this.setState({isEventsPopupOpen : true, currentEvent:data})
  }

  renderEventCard = (data: any) => {
    const { title,  location, start_time,start_date, event_posters, end_date, end_time } = data.attributes;
    const addrress = this.getLocation(location)
    const imageArray = event_posters;

    const CustomDot = ({ onClick, index, active, carouselState }: any) => {
      
      const { currentSlide } = carouselState;
      
      const distance = Math.abs(currentSlide - index);
      let dotSize = 4;  

      if(distance===0){
        dotSize=6;
      }
      else if(distance==1){
        dotSize=5;
      }
  
      return (
        <button
          className={active ? "active" : "inactive"}
          onClick={() => onClick()}
          style={{
            padding: `${dotSize}px`,
            height: `${dotSize}px`,
            width:`${dotSize}px`,
            borderRadius: '100%',
            backgroundColor: 'white', 
            border: 'none',
            margin: '10px 2px',
            transition: 'width 0.3s, height 0.3s',
          }}
        >
        </button>
      );
    };

    return(
        <div style={webStyle.cardContainer as React.CSSProperties} className="card-container">
          <Card style={webStyle.card as React.CSSProperties} className="card">
          <Carousel data-test-id="carousel" responsive={this.imgResponsive} swipeable={false} draggable={false} 
            showDots={true} removeArrowOnDeviceType={["tablet", "mobile","desktop"]} 
            infinite={false} autoPlay={true} autoPlaySpeed={6000}  customDot={<CustomDot />}
          >
              {imageArray.map((image: any) => {
                return (
                  <CardMedia
                    component="img"
                    alt={title}
                    height="281"
                    width="281"
                    image={image.url}
                    style={{ borderRadius: "16px" }}
                  />
                );
              })}
            </Carousel>
            <CardContent>
                <Typography style={webStyle.cardTitle as React.CSSProperties} className="cardTitle" gutterBottom variant="h6" component="div">{title}<br/> <span style={{color: data.attributes.categoryClr}}>Location Name/Venue</span></Typography>
                <Grid container style={webStyle.cardIconContainer as React.CSSProperties} >
                  <span><LocationOnOutlinedIcon style={webStyle.cardIcon} /></span>
                  <Typography style={webStyle.cardIconTxt as React.CSSProperties} variant="body2"> {addrress} </Typography>
                </Grid>
                <Grid style={webStyle.cardIconContainer as React.CSSProperties} container>
                  <AccessTimeIcon style={webStyle.cardIcon}/>
                  <Box display="flex" flexDirection="column">
                    <Typography  style={webStyle.cardIconTxt as React.CSSProperties}  variant="body2"> {this.formatDate(`${start_date}`)} </Typography>
                    <Typography  style={webStyle.cardIconTime as React.CSSProperties}  variant="body2"> {this.convertTo24HourFormat(`${start_time}`)} - {this.convertTo24HourFormat(`${end_time}`)} </Typography>
                  </Box>
                </Grid>
                <Grid container style={webStyle.cardFooter}>
                  <div style={{background: data.attributes.categoryClr || "gray", color: "white",padding:"13px 5px", borderRadius:"20px", alignContent: "center"}} className=""><Typography style={webStyle.categoryBtnText}>Category</Typography></div>
                  <div data-test-id="eventMarker" onClick={()=>this.displayEvent(data)} style={{color: data.attributes.categoryClr, textDecoration: "underline",transform: "translateY(15px)"}} className="">See More</div>
                </Grid>
            </CardContent>             
          </Card>
        </div>
    )
  }

  renderTodaySection = () => {
    return(
      <div style={webStyle.happeningTodaySection as React.CSSProperties} className="happening-today-section">
        <style>
          {`
            .carousel-item-padding-40-px{
                display: flex;
                justify-content: center;
            }
           
            .react-multiple-carousel__arrow {
              background: linear-gradient(99.09deg, rgba(254, 150, 18, 0.6) 2.64%, rgba(214, 35, 57, 0.6) 100%);
              box-shadow: 0px 4px 14px 0px #00000040;
            }

            .react-multiple-carousel__arrow:hover {
              background: linear-gradient(99.09deg, rgba(254, 150, 18, 0.8) 2.64%, rgba(214, 35, 57, 0.8) 100%);
              box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.35);
            }

            .react-multi-carousel-dot-list{
              align-Items: center;
            }

            .react-multiple-carousel__arrow--right{
            
            }
            .react-multiple-carousel__arrow--left{
            
            }
          `}
        </style>
        <Typography style={webStyle.happeningTodaySectionTitle(this.state.isMobile)} className="title">HAPPENING TODAY</Typography>
        <div style={webStyle.eventCardSection} className="event-card-section">
        <Container maxWidth="xl">
          <Carousel responsive={this.cardResponsive} swipeable={false} draggable={false} removeArrowOnDeviceType={["desktop"]} 
              itemClass="carousel-item-padding-40-px" autoPlay={false}
            >
            {this.state.events.map((event: any, index: number) => (
              <React.Fragment key={index}>
                {this.renderEventCard(event)}
              </React.Fragment>
            ))}
          </Carousel>
          </Container>
        </div>
      </div>      
    )

  }
  
  renderFooterSection = () => {
    return(
      <div>
        <Grid style={webStyle.footerSection2} container>
          <Link style={webStyle.footerLink} to="/">Terms of Use</Link>
          <Divider 
            sx={{border:"#fff solid 1px"}}
            flexItem 
            orientation="vertical" 
            />
          <Link data-test-id="linkPrivacy" style={webStyle.footerLink} to="/">Privacy Policy</Link>
        </Grid>

      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        {this.state.currentEvent &&
        <EventsPopUp open={this.state.isEventsPopupOpen} onClose={this.handleEventPopupClose} onClickShare={this.handleShareModalOpen} event={this.state.currentEvent} />}
        <ShareEventModal isOpen={this.state.isShareModalOpen} onClose={this.handleShareModalClose} 
        event = {this.state.currentEvent?.attributes || {}}
        />
        <ThemeProvider theme={theme}>
            <div className="positionRelative" style={webStyle.landingStyle}>
                <div style={webStyle.overLay as React.CSSProperties}></div>
                <div style={webStyle.landingLogoContent as React.CSSProperties} className="landing-logo-content">
                  <div style={webStyle.landingContentWithLogo as React.CSSProperties} className="">
                    <div className="">
                      <Typography style={webStyle.socialTitle(this.state.isMobile)}>SPARK</Typography>
                      <hr style={webStyle.socialTitelHr}/>
                      <Typography style={webStyle.socialDescription(this.state.isMobile)}>LGBTQ+ <span style={{fontFamily: 'Just Me Again Down Here',fontSize: this.state.isMobile ? "40px" : "45px", lineHeight: "10px", textAlign: "center"}}>Community</span></Typography>
                      <Link style={webStyle.btnOrange(this.state.isMobile) as React.CSSProperties} className="btn-orange" to="/events">Explore Events <span style={webStyle.rightArrowSpan(this.state.isMobile) as React.CSSProperties}><img src={arrowRight} /></span></Link>
                    </div>                   
                    <img data-test-id="logoImage" style={webStyle.landingContentIcon(this.state.isMobile)}  src={logo} />
                  </div>
                    
                </div>
                
            </div>
            {
               this.renderTodaySection()
            }
            {this.renderFooterSection()}
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  landingLogoContent: { margin: 'auto', textAlign: 'center', zIndex: 1 },
  landingContentWithLogo: { display: "flex", flexDirection: "row" },
  landingContentIcon:(isMobile:boolean)=>({
    height: "81px", 
    marginTop: "71px",
    ...(isMobile && {
      display:"none"
    })
  }),
  btnOrange:(isMobile:boolean)=> ({color: 'white', display: 'flex', fontFamily: 'Inter', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', width: '375px', height: '72px', borderRadius: '100px', backgroundColor: '#F47133', fontSize: '25px', fontWeight: 400, margin: 'auto' ,position:"relative", ...(isMobile && { width:"269px", fontSize:"18px"})}),
  rightArrowSpan:(isMobile:boolean)=> ({background: "#E1682F", width:"51px", height: "51px", borderRadius:"50%", alignContent:"center" ,position: "absolute",right: "10px" ,...(isMobile && {right: "10px"})}),
  calenderSection: { display: 'flex', flexDirection: 'column', justifyContent: 'center',gap: "44px", margin: '59px', textAlign: "center"},
  calenderContent: { marginTop: 'auto', marginBottom: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', maxWidth: '457px' },
  buttonGroup: { display: 'flex', flexDirection: 'row', gap: '15px', margin:"auto" },
  btnBorderOrange: { fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, lineHeight: '10px', width: '206px', height: '38px', borderRadius: '100px', border: '1px solid #F47133', color: '#F47133', display: 'flex', justifyContent: 'center', alignItems: 'center'},
  calenderBtnOrange: {fontFamily: 'Inter', width: "206px",height: "38px", borderRadius: "100px",  background: "#F47133", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", color: "white", display: "flex", justifyContent: "center", alignItems: "center"},
  eventsBtnRed:{fontFamily: 'Inter', width: "206px",height: "38px", borderRadius: "100px",  background: "#D62339", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", color: "white", display: "flex", justifyContent: "center", alignItems: "center"},
  happeningTodaySection: { fontFamily: 'Inter', textAlign: 'center', display: "flex", flexDirection: "column" },
  happeningTodaySectionTitle: (isMobile:boolean)=> ({ fontSize: '45px', fontWeight: 700, color:"#3C8D9E" ,display:"flex", margin: "74px auto 52px auto",...(isMobile && {fontSize:"28px"})}),
  overLay: {background: "linear-gradient(180deg, rgba(28, 27, 31, 0.3) 0%, #1C1B1F 100%)", position: "absolute", width: "100%", height: "100%", top:"0", left:"0" },
  socialTitle:(isMobile:boolean)=> ({fontFamily: 'Inter',fontSize: "100px",fontWeight: "600", margin: "0px", lineHeight: "1" ,...(isMobile && {fontSize:"70px"})}),
  socialTitelHr: {height: "3px", background: "white"},
  socialTitleDc: {fontFamily: 'Inter',fontSize: "40px",fontWeight: "600", margin: "0px"},
  socialDescription: (isMobile:boolean)=> ({fontFamily: 'Inter',fontSize: "35px",fontWeight: "400", margin:"0", height:"46px", paddingBottom:"34px",...(isMobile && {fontSize:"25px"})}),
  calenderTitle:{fontFamily:"Inter", fontSize: "45px", fontWeight: "700", lineHeight: "45px", margin: "0px" },
  landingStyle: { 
    position: 'relative' as 'relative',
    backgroundImage: `url(${landingBgImage})`,
    minHeight: "617px",
    width: "100%",
    marginTop: "-95px",
    display: "flex",
    color:"white",
    alignItems: "auto",
  },
  eventCardSection: { gap: "24px",marginBottom: "66px"},
  cardContainer: { width: '281px', position: 'relative', borderRadius: '16px', color: 'white' },
  card: { position: 'relative', borderRadius: '16px', height: "auto" ,boxShadow:"none",border:"1px solid #DADADA"},
  cardTitle: { fontFamily: 'Inter',fontSize: '16px', fontWeight: 600, lineHeight: '25px', textAlign: "left",marginBottom:'15px' },
  cardFooter: {display: 'flex', justifyContent: 'space-between'},
  addToFav: { position: 'absolute', top: '8px', right: '8px', background: '#E2E8F0', color: 'white', zIndex: 1 },
  cardIcon: { background: '#E2E8F0', padding: "5px", fontSize: "20px", borderRadius: "50%"},
  cardIconContainer: { display: "flex", flexWrap:"unset",gap: "10px", marginBottom:"16px"},
  cardIconTxt: {fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '15px', textAlign: 'left'},
  cardIconTime: {fontFamily: 'Inter', fontSize: '12px', fontWeight: '700', lineHeight: '15px', textAlign: 'left'},
  footerSection1:{background: "#045E81", display: "flex", justifyContent:"space-around", height: "101px", alignContent: "center", color: "white"},
  footerSection2:{background: "#043C61",display: "flex", justifyContent:"center", height: "57px", alignContent: "center", color: "white", gap: "25px"},
  footerLink: {color: 'white', display: 'flex', fontFamily: 'Inter', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', fontSize: '18px', fontWeight: 400,},
  footerIconContainer: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "10px",
    color:"white",
    textDecoration:"unset"
  },
  iconContainer:{background: 'white', padding: "7px", fontSize: "20px", borderRadius: "50%"},
  categoryBtnText: {fontFamily: "Inter" , fontSize: "16px",fontWeight: 400,lineHeight:"0"}
};
// Customizable Area End
