export const locationMarkerDarkBlue = require("../assets/location-marker-dark-blue.png");
export const locationMarkerOrange = require("../assets/location-marker-orange.png");
export const locationMarkerLightGreen = require("../assets/location-marker-light-green.png");
export const locationMarkerPink = require("../assets/location-marker-pink.png");
export const locationPin = require("../assets/locationPin.png");
export const instaIcon = require("../assets/instaIcon.png");




