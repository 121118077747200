Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getTodayEventsAPi = "bx_block_events/events/todays_events";
exports.getFilteredEventsApiEndPoint = "bx_block_events/events?";
exports.colorsArray = ["#F47133", "#043C61", "#BC3081", "#96B999", "#FE9612", "#3C8D9E", "#D62339"];
exports.noEventsLabel = "No events for the day";
exports.mapApiKey = "AIzaSyBwY6jkWhDvh133eBtU5AAwDdzhdG9I5co";
exports.getFilterOptionsApiEndPoint = "bx_block_events/events/filter_options";
// Customizable Area End