Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";

exports.btnExampleTitle = "CLICK ME";
exports.httpGetMethod="GET"
exports.httpPostMethod="POST"
exports.httpPutMethod="PUT"
exports.httpPatchMethod="PATCH"
exports.httpDeleteMethod="DELETE"

// Customizable Area End