export const landingBgImage = require("../assets/homebg.png");
export const landingSecondaryImage = require("../assets/secondaryImg.jpeg");
export const logo = require("../assets/logo1.png");
export const ellipse = require("../assets/Ellipse.png");
export const insta = require("../assets/instagram.svg").default;
export const favIcon = require("../assets/fav.png");
export const locationIcon = require("../assets/location24.png");
export const timeIcon = require("../assets/time24.png");
export const spark60 = require("../assets/spark60.png");
export const arrowRight = require("../assets/arrowRight24.png");
export const insta24 = require("../assets/Instagram24.png");
export const phone24 = require("../assets/phone24.png");
export const email24 = require("../assets/mail24.png");
export const locationBlue = require("../assets/location-blue.png");
export const profile = require("../assets/profile.svg").default;
export const dropdown = require("../assets/dropdown.svg").default;
export const aboutUs = require("../assets/aboutUs.png");
export const close = require("../assets/close.png");
export const calender = require("../assets/calender.png");
export const flash = require("../assets/flash.png");
export const profile_white = require("../assets/profile_white.png");
export const profile_blue = require("../assets/profile_blue.svg").default;
export const hamburger = require("../assets/hamburger.png");
export const topArrow = require("../assets/topArrow.svg");
export const addIcon = require("../assets/add-icon.png");
export const locationMarkerRed = require("../assets/location-marker-red.png");
export const checkbox = require("../assets/checkbox.png");
export const checkboxChecked = require("../assets/checkbox-checked.png");