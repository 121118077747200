// Customizable Area Start
import React from "react";

import {
  Box,
  styled,
  Divider,
  Button,
  CircularProgress
} from "@mui/material"

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from "moment";
import { locationBlue } from "../../landingpage/src/assets";
import { truthyValue } from "../../../components/src/utils";
import { IEvent } from "../../../components/src/typeInterfaces";

import EventCalendarController, {
  Props,
} from "./EventCalendarController";


class EventCalendar extends EventCalendarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCalendarHeaders = () => {
    return (
      <Box
        className="calendarHeaderBox"
      >
        <Box sx={webStyle.selectedDateOrMonthLabel}>{this.getDateString()}</Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} marginRight={"10px"}>
            <Box
              onClick={this.handleMoveBackwardInCalendar}
              data-test-id="backwardBtn"
            >
              <KeyboardArrowLeftIcon style={webStyle.moveIcon} />
            </Box>
            <Box
              onClick={this.handleMoveForwardInCalendar}
              data-test-id="forwardBtn"
            >
              <KeyboardArrowRightIcon style={webStyle.moveIcon} />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            borderRadius={"50px"}
            bgcolor={"#F8FAFC"}
          >
            <Box
              sx={
                this.state.calendarTypeView === this.typeOfCalendarView.monthView
                  ? webStyle.calendarView
                  : webStyle.calendarActiveView
              }
              onClick={() => this.handleCalendarViewChange("day")}
              data-test-id="dayView"
            >
              Day
            </Box>
            <Box
              sx={
                this.state.calendarTypeView === this.typeOfCalendarView.dayView
                  ? webStyle.calendarView
                  : webStyle.calendarActiveView
              }
              onClick={() => this.handleCalendarViewChange("month")}
              data-test-id="monthView"
            >
              Month
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderCommonEventsForDayView = (eventsArray : IEvent[] , mainIndex : number) => {
    return (
      <Box>
        {eventsArray.map((event, index) => {
          const globalIndex = mainIndex * eventsArray.length + index;
          const color =
            this.colorsArray[
            globalIndex % this.colorsArray.length
            ];
          return (
            <Box key={event.id}>
              <Box
                margin={"0px 5px 5px"}
                sx={webStyle.dayEventTile}
                alignItems={"center"}
                padding={"6px"}
              >
                <Box
                  sx={{
                    ...webStyle.eventLineForDayEvent,
                    backgroundColor: color,
                    color: color,
                  }}
                >
                  .
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Box sx={webStyle.dayEventTitle}>
                      {event.attributes.title}
                    </Box>
                    <Box margin={"0px 5px 0px 8px"} fontSize={"14px"}>
                      |
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <img
                          src={locationBlue}
                          width={"10px"}
                          alt="location"
                        />
                      </Box>
                      <Box
                        sx={webStyle.locationText}
                        marginLeft={"4px"}
                      >
                        {event.attributes?.location?.data?.attributes
                          ?.venue_name || "-"}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      ...webStyle.identityTag,
                      color: color,
                    }}
                  >
                    {event.attributes.identity_tags?.data?.length
                      ? Array.from(
                        new Set(
                          event.attributes.identity_tags?.data.map(
                            (item) => item?.attributes?.name
                          )
                        )
                      ).join(", ")
                      : "-"}
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          );
        })}

      </Box>
    )
  }

  renderDayViewSection = () => {

    const StyledDayEventList = styled("div")(({
      maxHeight: "450px",
      overflowY: "auto",
      marginBottom: "20px",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#E7E6E6 !important",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#043C61 !important",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: "#043C61 !important",
        },
      },
      "& .showEventText" : {
        fontSize: "10px",
        textDecoration : "underline",
        fontFamily: "Inter",
        fontWeight: "700",
        cursor : "pointer"
      }
    }));

    return (
      <Box>
        {Object.entries(this.state.dayEventsData).length > 0 ?
          <StyledDayEventList>
          {Object.entries(this.state.dayEventsData).map(
            ([timeSlot, events], mainIndex) => {
              const duplicatedId = this.findDuplicates(events)
              const singleEventArray: IEvent[] = events.filter(item => item.attributes.location && item.attributes.location.data && truthyValue(item.attributes.location.data.id) !== duplicatedId)
              const multipleEventArray: IEvent[] = events.filter(item => item.attributes.location && item.attributes.location.data && truthyValue(item.attributes.location.data.id) === duplicatedId)
              return (
                <Box padding={"0px 24px"} key={timeSlot}>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"20px"}>
                    <Box sx={webStyle.timeSlot}>
                      {moment(timeSlot, "hh:mm A").format("HH:mm")}
                    </Box>
                    <Box width={"100%"}>

                      {multipleEventArray.length > 0 ?
                        <Box>
                          <Box>
                            {multipleEventArray.slice(0,1).map((event, index) => {
                              const globalSingleMultiIndex = mainIndex * events.length + index;
                              const colorFirst =
                                this.colorsArray[
                                  globalSingleMultiIndex % this.colorsArray.length
                                ];
                              return (
                                <Box key={event.id}>
                                  <Box
                                    alignItems={"center"}
                                    margin={"0px 5px 5px"}
                                    sx={webStyle.dayEventTile}
                                    padding={"6px"}
                                    
                                  >
                                    <Box
                                      sx={{
                                        ...webStyle.eventLineForDayEvent,
                                        backgroundColor: colorFirst,
                                        color: colorFirst,
                                      }}
                                    >
                                      .
                                    </Box>
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      width={"100%"}
                                    >
                                      <Box display={"flex"} alignItems={"center"}>
                                        <Box sx={webStyle.dayEventTitle}>
                                         {multipleEventArray.length} Events <span style={{
                                          color : colorFirst
                                         }} className={"showEventText"} data-test-id="showEvents" onClick={() => this.toggleShowMoreEvent(mainIndex.toString())}>Show Events</span>
                                        </Box>
                                        <Box margin={"0px 5px 0px 8px"} fontSize={"14px"}>
                                          |
                                        </Box>
                                        <Box display={"flex"} alignItems={"center"}>
                                          <Box>
                                            <img
                                              src={locationBlue}
                                              width={"10px"}
                                              alt="locationSection"
                                            />
                                          </Box>
                                          <Box
                                            sx={webStyle.locationText}
                                            marginLeft={"4px"}
                                          >
                                            {event.attributes?.location?.data?.attributes
                                              ?.venue_name || "-"}
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Divider />
                                </Box>
                              );
                            })}
                          </Box>

                          <Box>
                            {this.state.showEventId ===  mainIndex.toString() && multipleEventArray.map((event, index) => {
                              const globalMultiIndex = mainIndex * events.length + index;
                              const colorMulti =
                                this.colorsArray[
                                  globalMultiIndex % this.colorsArray.length
                                ];
                              return (
                                <Box key={event.id}>
                                  <Box
                                    margin={"0px 5px 5px"}
                                    padding={"6px 6px 6px 30px"}
                                    sx={webStyle.dayEventTile}
                                    alignItems={"center"}
                                  >
                                    <Box
                                      sx={{
                                        ...webStyle.eventLineForDayEvent,
                                        color: colorMulti,
                                        backgroundColor: colorMulti,
                                      }}
                                    >
                                      .
                                    </Box>
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      width={"100%"}
                                    >
                                      <Box display={"flex"} alignItems={"center"}>
                                        <Box sx={webStyle.dayEventTitle}>
                                            {event.attributes.title}
                                        </Box>
                                        <Box margin={"0px 5px 0px 8px"} fontSize={"14px"}>
                                          |
                                        </Box>
                                        <Box display={"flex"} alignItems={"center"}>
                                          <Box>
                                            <img
                                              src={locationBlue}
                                              width={"10px"}
                                              alt="location"
                                            />
                                          </Box>
                                          <Box
                                            marginLeft={"4px"}
                                            sx={webStyle.locationText}
                                          >
                                            {event.attributes?.location?.data?.attributes
                                              ?.venue_name || "-"}
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          ...webStyle.identityTag,
                                          color: colorMulti,
                                        }}
                                      >
                                        {event.attributes.identity_tags?.data?.length
                                          ? Array.from(
                                            new Set(
                                              event.attributes.identity_tags?.data.map(
                                                (item) => item?.attributes?.name
                                              )
                                            )
                                          ).join(", ")
                                          : "-"}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Divider />
                                </Box>
                              );
                            })}
                          </Box>

                          <Box>
                            {this.renderCommonEventsForDayView(singleEventArray,mainIndex)}
                          </Box>

                        </Box>

                        : this.renderCommonEventsForDayView(events,mainIndex)
                        
                      }
                      
                    </Box>
                  </Box>
                </Box>
              )
            }
          )}
        </StyledDayEventList> :
        <Box sx={webStyle.timeSlot} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} margin={"40px"}>
            No events for the day
        </Box>
        }
      </Box>
    );
  };

  renderMonthViewSection = () => {

    const localizer = momentLocalizer(moment);

    return (
      <Box>
        <Button data-test-id="redirectToDayView" sx={webStyle.hiddenButton} onClick={() => this.redirectToDayView(moment(new Date, "YYYY-MM-DD"))}></Button>

        <StyledCalendarWrapper>
          <Calendar
            localizer={localizer}
            events={this.state.calendarEventsData}
            startAccessor="start"
            endAccessor="end"
            toolbar={false}
            view={"month"}
            data-test-id="calendarLibrary"
            date={this.state.currentDate}
            components={{
              event: (toolbar) => {
                const eventDate = moment(
                  toolbar.event.start,
                  "YYYY-MM-DD"
                );
                const dayOfWeek = eventDate.day();
                const color = this.colorsArray[dayOfWeek];
                return (
                  <Box
                    sx={webStyle.monthEventTile}
                  onClick={() => this.redirectToDayView(eventDate)}
                  >
                    <Box
                      sx={{
                        ...webStyle.eventLine,
                        backgroundColor: color,
                        color: color,
                      }}
                    >
                      .
                    </Box>
                    <Box>
                      <Box sx={webStyle.eventCount}>{toolbar.title}</Box>
                      <Box sx={webStyle.eventTitle}>Events</Box>
                    </Box>
                  </Box>
                );
              },
              header: ({ label }) => (
                <Box sx={webStyle.dayHeader}>{label}</Box>
              ),
            }}
            eventPropGetter={() => ({
              style: webStyle.eventPropGetter,
            })}
            style={webStyle.calendarComponent}
          />
        </StyledCalendarWrapper>
      </Box>
    )
  }

  renderLoaderSection = () => {
    return (
      <Box style={webStyle.loaderDiv}>
          <CircularProgress size={40} color={"error"} />
      </Box>
    )
  }

  handleConditionCheck = () => {
    return (
      this.state.calendarTypeView === this.typeOfCalendarView.monthView
        ?
        this.renderMonthViewSection()
        :
        this.renderDayViewSection()
    )
  }
  
  render() {
    // Customizable Area Start

    return (
      <div>

        <Wrapper>

         {this.renderCalendarHeaders()}

         {this.state.calendarLoader ? this.renderLoaderSection() : this.handleConditionCheck()}

        </Wrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default EventCalendar;

const StyledCalendarWrapper = styled("div")(({
  ".rbc-header": {
    borderLeft: "none !important",
    backgroundColor: "#FFF !important",
  },
  ".rbc-month-view": {
    borderLeft: "none !important",
    borderRight: "none !important",
    borderTop: "none !important",
  },
  ".rbc-button-link": {
    color: "#94A3B8 !important",
    fontSize: "14px !important",
    fontFamily: "Inter !important",
    fontWeight: "400 !important",
    lineHeight: "22px !important",
  },
  ".rbc-off-range-bg": {
    backgroundColor: "#FAF7F7 !important",
  },
  ".rbc-today": {
    backgroundColor: "#FAF7F7 !important",
  }
}));

const Wrapper = styled(Box)({
  border: "1px solid transparent",
  backgroundColor: "#FFF",
  borderRadius: "10px",
  padding : "30px 45px",
  "@media (max-width: 600px)" : {
    padding: "15px 30px",
  },
  "& .calendarHeaderBox" : {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px 0px",
    "@media (max-width: 600px)" : {
      margin: "30px 0px",
    },
    "@media (max-width: 450px)" : {
      margin: "30px 0px",
    }
  }
})

const webStyle: { [key: string]: React.CSSProperties } = {
  loaderDiv : {
    textAlign : "center",
    margin : "200px auto"
  },
  selectedDateOrMonthLabel: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "28px",
    color: "#000",
  },
  calendarView: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#64748B",
    cursor: "pointer",
    padding: "2px 12px",
    border: "1px solid transparent",
    background: "#F8FAFC"
  },
  calendarActiveView: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#96B999",
    backgroundColor: "#EEF6EE",
    cursor: "pointer",
    borderRadius: "50px",
    border: "1px solid #96B999",
    padding: "2px 12px",
  },
  monthEventTile: {
    borderRadius: "5px",
    backgroundColor: "#FFF",
    display: "flex",
    padding: "2px",
  },
  eventLine: {
    margin: "5px 7px 5px 0px",
    borderRadius: "100px",
  },
  eventCount: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "14px",
    color: "#343434",
  },
  eventTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "14px",
    fontStyle: "italic",
    color: "#343434",
  },
  eventPropGetter: {
    backgroundColor: "transparent",
    border: "none",
    marginTop: "10px",
  },
  calendarComponent: {
    height: 500,
    backgroundColor: "#FAF7F7",
  },
  dayHeader: {
    fontSize: "12px",
    color: "#94A3B8",
    padding: "10px",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "#FFF",
  },
  moveIcon: {
    color: "#94A3B8",
    cursor: "pointer",
  },
  calendarEventContainer: {
    flex: 1,
    minHeight: "min(100vh, 736px)",
    maxWidth: "623px",
    maxHeight: "736px",
    borderRadius: "10px",
    padding: "20px",
  },
  timeSlot: {
    fontSize: "12px",
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "18px",
  },
  dayEventTile: {
    display: "flex",
    boxShadow: "0px 2px 2px 0px #0000001A",
    borderRadius: "5px",
  },
  eventLineForDayEvent: {
    margin: "5px 7px 5px 0px",
    borderRadius: "100px",
  },
  dayEventTitle: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "700",
    lineHeight: "10px",
  },
  locationText: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "10px",
  },
  identityTag: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "700",
    lineHeight: "10px",
  },
  hiddenButton: {
    display: "none",
  },
};

// Customizable Area End