import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { SelectChangeEvent } from "@mui/material/Select";

export type CalendarViewType = "month" | "week" | "work_week" | "day" | "agenda";

interface IEventData {
  data: IEvent[];
  meta: {
    events_day_counts: Record<string, number>;
    events_total_count: number;
  };
}

interface IEvent {
  id: string;
  attributes: IEventAttributes;
}

interface IEventAttributes {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  description: string;
  location: ILocation | null;
  identity_tags: IdentityTag | null;
}

interface ILocation {
  data: {
    id: string;
    attributes: ILocationAttributes;
  };
}

interface ILocationAttributes {
  id: number;
  venue_name: string;
  address: string;
  latitude: number | null;
  longitude: number | null;
  image: string | null;
}

interface IdentityTag {
  data: {
    id: string;
    attributes: {
      id: number;
      name: string;
    };
  }[];
}

interface ICalendarEvents {
  title: string;
  start: Date;
  end: Date;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: string;
  selectedEvents: string[];
  selectedCommunities: string[];
  searchQuery: string;
  calendarView: CalendarViewType;
  eventsData: IEventData;
  calendarEventsData: ICalendarEvents[];
  dayEventsData: Record<string, IEvent[]>;
  isEventsPopupOpen : boolean;
  isShareModalOpen : boolean;
  currentEvent : any;
  eventsLength : number
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class EventScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleEventPopupClose = this.handleEventPopupClose.bind(this);
    this.handleShareModalOpen = this.handleShareModalOpen.bind(this);
    this.handleShareModalClose = this.handleShareModalClose.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedDate: new Date().toLocaleDateString(),
      selectedEvents: [],
      selectedCommunities: [],
      searchQuery: "",
      calendarView: "month",
      eventsData: {
        data: [],
        meta: {
          events_day_counts: {},
          events_total_count: 0
        }
      },
      calendarEventsData: [],
      dayEventsData: {},
      isEventsPopupOpen : false,
      isShareModalOpen : false,
      currentEvent : {},
      eventsLength : 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getFilteredEventsApiId && responseJson) {
        const eventData = this.state.calendarView === "month" ? Object.entries(responseJson.meta.events_day_counts).map(([date, count]) => ({
          start: new Date(date),
          end: new Date(date),
          title: count as string,
        })) : [];
        const sortedEvents = responseJson.data.sort((aEvent: IEvent, bEvent: IEvent) => {
          const timeA = moment(aEvent.attributes.start_time, "hh:mm A");
          const timeB = moment(bEvent.attributes.start_time, "hh:mm A");
      
          return timeA.isBefore(timeB) ? -1 : 1;
        });
        const dayEventsData = this.groupEventsByHour(sortedEvents);
        this.setState({ eventsData: {...responseJson, data: sortedEvents }, calendarEventsData: eventData, dayEventsData: dayEventsData });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getFilteredEventsApiId: string = "";
  getFilteredOptionsApiId: string = "";

  componentDidMount = async () => {
    this.getFilteredEventsData();
    this.getFilteredOptionsData();
  };

  groupEventsByHour = (eventData: IEvent[]) => {
    const groupedEvents: Record<string, IEvent[]> = {};
  
    eventData.forEach((event) => {
      const eventStartTime = moment(event.attributes.start_time, "hh:mm A");
      const hourSlot = eventStartTime.format("H a");
  
      if (!groupedEvents[hourSlot]) {
        groupedEvents[hourSlot] = [];
      }
      groupedEvents[hourSlot].push(event);
    });
  
    return groupedEvents;
  };

  handleEventChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    this.setState({
      selectedEvents: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleCommunityChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    this.setState({
      selectedCommunities: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };


  getFilteredEventsData = () => {
    const newDate = new Date(this.state.selectedDate);
    let endPointString =
      configJSON.getFilteredEventsApiEndPoint +
      `${
        this.state.calendarView === "day"
          ? `date=${moment(newDate).format("YYYY-MM-DD")}`
          : `month=${moment(newDate).format(
              "MM"
            )}&year=${moment(newDate).format("YYYY")}`
      }&search=${this.state.searchQuery}`;
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredEventsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointString
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilteredOptionsData = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredOptionsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterOptionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEventPopupClose(){
    this.setState({isEventsPopupOpen : false})
  }

  handleShareModalOpen(){
    this.setState({
      isEventsPopupOpen : false,
      isShareModalOpen : true
    })
  }

  handleShareModalClose(){
    this.setState({
      isShareModalOpen : false,
      isEventsPopupOpen : true,
    })
  }

  updateEventsResult = (newValue: number, mapData : IEventData) => {
    this.setState({ eventsLength: newValue, eventsData : mapData });
  };

  updateCalendarType = (calendarType : CalendarViewType) => {
    this.setState({ calendarView : calendarType})
  }

  // Customizable Area End
}
