import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  selectedCommunities: string[];
  selectedIds: string[] 
  selectedEvents: string[];
  openSearchBox:boolean,
  filterOptions:any,
  selectedSubOptions: any,
  eventOpen:boolean,
  openSubmenu: string|null,
  expandlessMore:boolean,
  expandless:boolean,
  selectedValue:string,
  anchorEl:any,
  searchQuery: string;
  hideForMobile:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  timeoutId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpenEvents = this.handleOpenEvents.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      selectedCommunities: [],
      selectedIds: [] ,
      selectedEvents: [],
      openSearchBox:false,
      filterOptions:[],
      selectedSubOptions:{},
      eventOpen:false,
      expandless:false,
      expandlessMore:false,
      openSubmenu: '',
      selectedValue:'',
      anchorEl:'',
      searchQuery: "",
      hideForMobile:false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleResize=()=> {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); 
    }
    this.timeoutId = setTimeout(() => {
      if (window.innerWidth < 900) {
        this.setState({ hideForMobile: true });
      } else {
        this.setState({ hideForMobile: false });
      }
    }, 100); 
  }
  lessShow = () => {
    this.setState({ expandless: false })
  }
  moreShow = () => {
    this.setState({ expandless: true })
  }
  lessShowHandle = () => {
    this.setState({ expandlessMore: false })
  }
  moreShowHandle = () => {
    this.setState({ expandlessMore: true })
  }
  
  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    window.removeEventListener("resize", this.handleResize);
  } 
  getFilteredOptionsApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getFilteredOptionsData();
    this.timeoutId = null;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
      this.handleFilter(responseData, responseJson);

  }
  

    // Customizable Area End
  }
  handleFilter = (responseData: any, responseJson: any) => {
    if (this.getFilteredOptionsApiId && this.getFilteredOptionsApiId === responseData && responseJson && !responseJson?.errors) {
      
      this.setState({filterOptions:responseJson})
    }
  }


  // Customizable Area Start
 
  handleOpen = (event:any) => {
    if(event?.currentTarget){
    this.setState({ openSearchBox: true, anchorEl: event.currentTarget });
    }
  };
  
  handleClose = () => {
    this.setState({ openSearchBox: false, anchorEl: null });
  };
   handleSubmenuToggle = (option: string) => {
      this.setState((prevState:any) => ({
        openSubmenu: prevState.openSubmenu === option ? null : option,
      }));
    };
  handleEventSelect = (event:any, option:any) => {
    const { selectedEvents } = this.state;
    const isSelected = selectedEvents.includes(option);
    if (isSelected) {
      this.setState({
        selectedEvents: selectedEvents.filter(event => event !== option),
      });
    } else {
    
      this.setState({
        selectedEvents: [...selectedEvents, option],
      });
    }
  };
  handleCommunitySelect = (option:any) => {
    const { selectedCommunities, selectedIds } = this.state;
    if (selectedCommunities?.includes(option?.name)) {
      this.setState({
        selectedCommunities: selectedCommunities?.filter((name) => name !== option?.name),
        selectedIds: selectedIds?.filter((id) => id !== option?.id)
      });
    } else {
      this.setState({
        selectedCommunities: [...selectedCommunities, option?.name],
        selectedIds: [...selectedIds, option?.id]
      });
    }
  };
  handleSelect = (option:any) => {
    this.setState({selectedValue:option})
    this.handleClose();
  };
  handleOpenEvents=()=>{
    this.setState({eventOpen:true})
  }
  
  handleSearchChange = (event: any) => {
    this.setState({ selectedValue: event.target.value,openSearchBox: true, anchorEl: event.currentTarget });
  };
  handleCommunityChange = (event: any) => {
    const { value } = event.target;
    this.setState({
      selectedCommunities: typeof value === "string" ? value.split(",") : value,
    });
  };
  handleSubOptionSelect = (mainOption:any, subOption:any) => {
    this.setState((prevState:any) => {
      const currentSubOptions = prevState.selectedSubOptions[mainOption] || [];
      const updatedSubOptions = currentSubOptions.includes(subOption)
        ? currentSubOptions.filter((item:any) => item !== subOption)
        : [...currentSubOptions, subOption];
      return {
        selectedSubOptions: {
          ...prevState.selectedSubOptions,
          [mainOption]: updatedSubOptions,
        },
      };
    });
  };

  getFilteredOptionsData = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredOptionsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterOptionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleEventChange = (event: any) => {
    const { value } = event.target;
    this.setState({
      selectedEvents: typeof value === "string" ? value.split(",") : value,
    });
  };
 
  // Customizable Area End
}
