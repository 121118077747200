// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/utils'
import moment, {Moment} from "moment";
import { CalendarViewType, ICalendarEvents, IEvent, IEventData } from "../../../components/src/typeInterfaces";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  updateResultLength : (value : number, eventsData : IEventData) => void
  updateCalendarType : (value : CalendarViewType) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start 
  currentDate : string
  calendarTypeView : CalendarViewType,
  eventsData: IEventData;
  calendarEventsData: ICalendarEvents[];
  dayEventsData: Record<string, IEvent[]>;
  calendarLoader : boolean
  showEventId : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EventCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCalendarEventsApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      currentDate : new Date().toLocaleDateString(),
      calendarTypeView : "month",
      eventsData: this.initialEventsData,
      calendarEventsData: [],
      dayEventsData: {},
      calendarLoader : false,
      showEventId : "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getCalendarEventsApiCallId) {
          return this.handleResponseForGetCalendarApi(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getCalendarEventsData()
  }

  handleResponseForGetCalendarApi = (responseJson: any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {
      const eventCalendarData = this.state.calendarTypeView === this.typeOfCalendarView.monthView ? Object.entries(responseJson.meta.events_day_counts).map(([dateField, countField]) => ({
        start: new Date(dateField),
        end: new Date(dateField),
        title: countField as string,
      })) : [];
      const sortedCalendarEvents = responseJson.data.sort((aEvent: IEvent, bEvent: IEvent) => {
        const timeCalendarA = moment(aEvent.attributes.start_time, "hh:mm A");
        const timeCalendarB = moment(bEvent.attributes.start_time, "hh:mm A");

        return timeCalendarA.isBefore(timeCalendarB) ? -1 : 1;
      });
      const dayCalendarEventsData = this.groupEventsByHour(sortedCalendarEvents);
      this.setState({ eventsData: { ...responseJson, data: sortedCalendarEvents }, calendarEventsData: eventCalendarData, dayEventsData: dayCalendarEventsData, calendarLoader: false })
      const eventsLength = truthyValue(responseJson.meta.events_total_count)
      this.props.updateResultLength(eventsLength, { ...responseJson, data: sortedCalendarEvents } )
    } else {
       this.props.updateResultLength(0, this.initialEventsData)
       this.setState({ calendarLoader : false, dayEventsData : {}, calendarEventsData : [], eventsData : this.initialEventsData})
    }
  }

  findDuplicates = (events : IEvent[]) => {
    const locationArr = events.map(event => event.attributes.location && event.attributes.location.data ? truthyValue(event.attributes.location.data.id) : null)
    const frequencyMap = locationArr.reduce((acc, item) => {
     
      if (item !== null) {
        acc[item] = (acc[item] || 0) + 1;
      } else {
        acc['null'] = (acc['null'] || 0) + 1;  
      }
      return acc;
    }, {});
    
    
    const duplicatedItems = Object.keys(frequencyMap)
      .filter(item => frequencyMap[item] > 1)
      .map(item => item === 'null' ? null : Number(item));  

      return duplicatedItems.length > 0 ? duplicatedItems.toString() : ""
  }

  toggleShowMoreEvent = (eventId : string) => {
     this.setState({ showEventId : eventId })
  }

  typeOfCalendarView = {
    monthView : "month",
    dayView : "day"
  }

  colorsArray = ["#F47133", "#043C61", "#BC3081", "#96B999", "#FE9612", "#3C8D9E", "#D62339"];

  initialEventsData : IEventData = {
    data : [],
    meta: {
      events_day_counts: {},
      events_total_count: 0
    }
  }

  getCalendarEventsData = () => {
 
    this.setState({ calendarLoader : true, showEventId : ""})

    const newDate = new Date(this.state.currentDate);
    let endPointString =
      `${configJSON.getCalendarEventsApiEndPoint}?` +
      `${
        this.state.calendarTypeView === this.typeOfCalendarView.dayView
          ? `date=${moment(newDate).format("YYYY-MM-DD")}`
          : `month=${moment(newDate).format(
              "MM"
            )}&year=${moment(newDate).format("YYYY")}`
      }`;
    const header = { 
      "Content-Type": configJSON.validationApiContentType 
    };

    this.getCalendarEventsApiCallId = apiCalling({
      header : JSON.stringify(header),
      method : configJSON.httpGetMethod,
      endPoint : endPointString
    })
  }

  groupEventsByHour = (eventData: IEvent[]) => {
    const groupedEvents: Record<string, IEvent[]> = {};
  
    eventData.forEach((event) => {
      const eventStartTime = moment(event.attributes.start_time, "hh:mm A");
      const hourSlot = eventStartTime.format("H a");
  
      if (!groupedEvents[hourSlot]) {
        groupedEvents[hourSlot] = [];
      }
      groupedEvents[hourSlot].push(event);
    });
  
    return groupedEvents;
  };

  getDateString = () => {
    const newDate = new Date(this.state.currentDate);
    if (this.state.calendarTypeView === this.typeOfCalendarView.dayView) {
      return moment(newDate).format("dddd, MMMM DD YYYY");
    } else {
      return moment(newDate).format("MMMM YYYY");
    }
  };

  handleMoveForwardInCalendar = () => {
    const currentDate = new Date(this.state.currentDate);
    let newDate: number | string = "";
    if (this.state.calendarTypeView === this.typeOfCalendarView.dayView) {
      newDate = currentDate.setDate(currentDate.getDate() + 1);
    } else if (this.state.calendarTypeView === this.typeOfCalendarView.monthView) {
      newDate = currentDate.setMonth(currentDate.getMonth() + 1);
    }
    this.setState({ ...this.state, currentDate: new Date(newDate).toLocaleDateString() },  () => {
      this.getCalendarEventsData();
    });
  };

  handleMoveBackwardInCalendar = () => {
    const currentDateForPrev = new Date(this.state.currentDate);
    let newDateForPrev: number | string = "";
    if (this.state.calendarTypeView === this.typeOfCalendarView.dayView) {
      newDateForPrev = currentDateForPrev.setDate(
        currentDateForPrev.getDate() - 1
      );
    } else if (this.state.calendarTypeView === this.typeOfCalendarView.monthView) {
      newDateForPrev = currentDateForPrev.setMonth(
        currentDateForPrev.getMonth() - 1
      );
    }
    this.setState({ ...this.state, currentDate: new Date(newDateForPrev).toLocaleDateString() }, () => {
      this.getCalendarEventsData();
    });
  };

  handleCalendarViewChange = (view: CalendarViewType) => {
    this.setState({ calendarTypeView: view }, () => {
      this.props.updateCalendarType(view)
      this.getCalendarEventsData();
    });
  };

  redirectToDayView = (eventDate: Moment) => {
    this.setState({ currentDate: eventDate.toISOString(), calendarTypeView: "day" }, () => {
      this.props.updateCalendarType("day")
      this.getCalendarEventsData();
    })
  };

  // Customizable Area End
}

// Customizable Area End