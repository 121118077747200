import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {toast } from "react-toastify";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from 'react' 
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  emailError:string,
  emailCheck:boolean,
  passwordError:string,
  passwordCheck:boolean,
  handleError:boolean,
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  user:{
    full_name:string;
    email_id:string;
    password:string;
  };
  termsnCondition:boolean;
  termsnConditionModal:boolean;
  error:{
    full_name:string;
    email_id:string;
    password:string;
  }
  isMobile: boolean;
  termsnConditionCancel:boolean;
  termAndConditionResponse:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  postSignupApiCallId:any;
  getTnCDataApiCallId:any;
  timeoutId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      emailError:"",
      emailCheck:false,
      passwordError:"",
      passwordCheck:false,
      enableReTypePasswordField: true,
      handleError:false,
      countryCodeSelected: "",
      phone: "",
      user:{
        full_name:"",
        email_id:"",
        password:"",
      },
      termsnCondition:false,
      termsnConditionModal:false,
      error:{
        full_name:"",
        email_id:"",
        password:"",
      },
      isMobile: false,
      termsnConditionCancel:false,
      termAndConditionResponse:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        this.postSignupCall(message)
        this.getTncCall(message);
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount() {
    super.componentDidMount();

    this.timeoutId = null;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchTnCDescription();
  }

  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize=()=> {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); 
    }
    this.timeoutId = setTimeout(() => {
      if (window.innerWidth < 900) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false });
      }
    }, 100); 
  }

  postSignupCall=(message:Message)=>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postSignupApiCallId !== "" &&
      this.postSignupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && responseJson[0]?.errors?.email[0]){
        this.setState({emailError:responseJson[0]?.errors?.email[0],emailCheck:true})
      }
      if(responseJson && responseJson?.errors?.password){
        this.setState({passwordError:responseJson?.errors?.password,passwordCheck:true})
      }
      if(responseJson && responseJson?.message){  
        toast.success(responseJson.message);
        setTimeout(() => {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        }, 1000);
      }
      if (responseJson) {
        this.handleSignupResponse(responseJson)
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }
 
  getTncCall=(message:Message)=>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTnCDataApiCallId !== "" &&
      this.getTnCDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.handleTnCResponse(responseJson)
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleGoBack=()=>{
    this.props.navigation.goBack()
  }


  handleNameChange=(e: ChangeEvent<HTMLInputElement>)=>{
    const {value} =e.target;

    this.setState(prevState => ({
      user: {
        ...prevState.user, 
        full_name: value,   
      }
    }))
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  }

  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({emailCheck:false})
    const { value } = e.target;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        email_id: value
      }
    }));

  };

  handlePasswordChange=(e: ChangeEvent<HTMLInputElement>)=>{
    this.setState({passwordCheck:false})
    const {value} =e.target;
    this.setState(prevState => ({
      user: {
        ...prevState.user, 
        password: value,   
      }
    }))
  }

  handleTnCChange=(e: ChangeEvent<HTMLInputElement>)=>{
    const { checked } = e.target; 
    this.setState({
      termsnCondition: checked,
    });
  }

  handleSignupResponse=(responseJson:any)=>{
    if(responseJson.message=="Account created successfully"){
      setStorageData("data",JSON.stringify(responseJson.data.data))
      setStorageData("meta",JSON.stringify(responseJson.meta));
      this.props.navigation.navigate('/')
    }
  }

  handleTnCResponse=(responseJson:any)=>{
      const formattedDescription = responseJson.data[0].description.split(/\r\n/);
      this.setState({
        termAndConditionResponse:formattedDescription
      },()=>console.log(this.state.termAndConditionResponse,"termAndConditionResponse"))
  }

  handletncClose=()=>{
    this.setState({
      termsnConditionModal:false,
      termsnCondition:this.state.termsnConditionCancel
    })
  }

  handletncAgree=()=>{
    if(this.state.termsnCondition){
      this.setState({
        termsnCondition:true,
        termsnConditionModal:false,
      })
    }
  }

  navigateToLogin=()=>{
    this.props.navigation.navigate('EmailAccountLoginBlock')
  }

  openTermnConditionModel=()=>{
    this.setState({
      termsnConditionModal:true,
      termsnConditionCancel:this.state.termsnCondition
    })
  }

  fetchTnCDescription = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const postSignupMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTnCDataApiCallId = postSignupMsg.messageId;

    postSignupMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tncDataApiEndpoint
    );

    postSignupMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    postSignupMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(postSignupMsg.id, postSignupMsg);
  }


  handleSignupSubmit = () => {
    const { full_name, email_id, password } = this.state.user;
    let newError = { ...this.state.error };

    if (full_name === "") {
      newError.full_name = "Name is required";
    } else {
      newError.full_name = "";
    }

    if (email_id === "") {
      newError.email_id = "Email is required";
    } else if (!this.validateEmail(email_id)) {
      newError.email_id = "Email is not valid";
    } else {
      newError.email_id = "";
    }

    if (password === "") {
      newError.password = "Password " + configJSON.errorLabel;
    } else {
      newError.password = "";
    }

    this.setState({
      error: newError,
    });
    this.setState({handleError:true})
 
    if (!newError.full_name && !newError.email_id && !newError.password) {

      if (this.state.termsnCondition) {
        this.setState({handleError:false})
        const headers = {
          "Content-Type": configJSON.validationApiContentType
        };

        const payload = {
          "data": {
            "type": "email_account",
            "attributes": {
              "full_name": this.state.user.full_name,
              "email": this.state.user.email_id,
              "password": this.state.user.password,
              "activated": true
            }
          }
        }

        const postSignupMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postSignupApiCallId = postSignupMsg.messageId;

        postSignupMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.signupCredApiEndpoint
        );

        postSignupMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        postSignupMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiMethodTypeAddDetail
        );

        postSignupMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(payload)
        );
        runEngine.sendMessage(postSignupMsg.id, postSignupMsg);
      }
    }
  }
  // Customizable Area End
}
