// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
} from "@mui/material"
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { instaIcon, locationPin } from "./assets";

import EventMapController, {
    Props
} from "./EventMapController";


class EventMap extends EventMapController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
      const center = {
          lat: 38.8950368,
          lng: -77.0365427,
      };
    
      return (
          <div>
              <MapWrapper>

                  <Box className="mapBox">
                      {this.state.isGoogleScriptLoaded ? (
                          <GoogleMap
                              mapContainerStyle={{
                                  height: "100%",
                                  width: "100%",
                                  
                              }}
                              mapContainerClassName="mapComponent"
                              center={center}
                              zoom={12}
                              options={{
                                  disableDefaultUI: true,
                                  zoomControl: true,
                              }}
                          >  
                              {this.state.mapEventsData.length > 0 && this.state.mapEventsData.map((events, index) => (
                                      <Marker
                                          key={index}
                                          position={{
                                              lat: this.handletLatCheck(events),
                                              lng: this.handletLongCheck(events),
                                          }}
                                          icon={{
                                              url: this.handleDynamicMarker(index),
                                              scaledSize: { width: 64, height: 64, equals: () => true }
                                          }}
                                          data-test-id="eventMarker"
                                          onClick={() => this.openMapPopUp(events)}
                                      >

                                          {this.state.mapPopUpId === events.id &&
                                                  <InfoWindow
                                                      position={{
                                                          lat: this.handletLatCheck(events),
                                                          lng: this.handletLongCheck(events),
                                                      }}
                                                      onCloseClick={this.closeMapPopUp}
                                                      data-test-id="mapPopUp"
                                                  >
                                                      <Box data-test-id="clickPopUp" onClick={() => this.props.showEvent(events)} bgcolor={this.handleDynamicBgColor(index)} className="mainPopUpWrapper">
                                                          <Box className="imgBox">
                                                              <img height={"80px"} width={"84px"} style={{
                                                                  border: "1px solid #fff",
                                                                  borderRadius: "5px"
                                                              }} src={this.state.eventInfo.eventImage} />
                                                          </Box>
                                                          <Box>
                                                              <Typography className="titleText">{this.state.eventInfo.eventTitle}</Typography>
                                                              <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                                                                  <img src={locationPin} height={20} width={20} />
                                                                  <Typography className="addressText">{this.state.eventInfo.eventAddress}</Typography>
                                                              </Box>
                                                              <Box sx={{ margin: "5px 0" }} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                  <Box className="tagDiv">
                                                                      <Typography className="tagText">LGBTQ+</Typography>
                                                                  </Box>
                                                                  <img src={instaIcon} height={20} width={20} />
                                                              </Box>
                                                          </Box>
                                                      </Box>
                                                  </InfoWindow>
                                          }

                                      </Marker>
                              ))}
                          </GoogleMap>
                      ) : (
                          <></>
                      )}
                  </Box>

                  
              </MapWrapper>
          </div>
      );
    // Customizable Area End
  }
}

export default EventMap;

const MapWrapper = styled(Box)({
  "& .mapBox" : {
    height : "736px",
    background : "#fff",
   "@media (max-width: 700px)" : {
        height : "375px",
    },
  },
  "& .mapComponent" : {
    borderRadius: "20px",
    "@media (max-width: 700px)" : {
        borderRadius: "0px",
    },
  },
  "& .mainPopUpWrapper" : {
    padding : 10,
    display : "flex",
    gap : 10, 
    minWidth : "240px",
    maxWidth : "300px",
    borderRadius : "10px",
    cursor : "pointer"
  },
  "& .titleText" : {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
      color : "#fff",
      marginBottom : "5px"
  },
  "& .addressText" : {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 400,
      color: "#fff",
      maxWidth : "150px"
  },
  "& .tagText" : {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color : "#BC3081",
  },
  "& .tagDiv" : {
    padding : "5px 10px",
    background : "#fff",
    borderRadius : "50px",
    border: "1px solid #BC3081"
  }
})
// Customizable Area End