// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import {  IEvent, EventInfo } from "../../../components/src/typeInterfaces";
import { locationMarkerDarkBlue, locationMarkerLightGreen, locationMarkerOrange, locationMarkerPink } from "./assets";
import { locationMarkerRed } from "../../landingpage/src/assets";
import { truthyValue } from "../../../components/src/utils";

export const configJson = require("../../landingpage/src/config");
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  eventsMapData : IEvent[]
  showEvent : (events : IEvent) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  mapEventsData : IEvent[],
  isGoogleScriptLoaded: boolean
  mapPopUpId : string,
  eventInfo : EventInfo,
  selectedColorCode : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EventMapController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        mapEventsData : [],
        isGoogleScriptLoaded : false,
        mapPopUpId : "",
        eventInfo : this.initialEventInfo,
        selectedColorCode : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // some code here
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
      if(prevProps.eventsMapData !== this.props.eventsMapData) {
         this.setState({ mapEventsData : this.props.eventsMapData})
      }
  }

  async componentDidMount() {
     this.loadScript()
  }

    loadScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${configJson.mapApiKey}&libraries=&v=weekly`;
        script.async = true;
        script.onload = () => this.setState({ isGoogleScriptLoaded: true });
        script.onerror = () => console.error("Google Maps API script failed to load");
        document.head.appendChild(script);
    }

  handletLatCheck = (events : IEvent) => {
        let lat = 0
        if(events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes && events.attributes.location.data.attributes.latitude) {
            lat = events.attributes.location.data.attributes.latitude
        }
        return lat
  }

  handletLongCheck = (events: IEvent) => {
    let long = 0
    if (events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes && events.attributes.location.data.attributes.longitude) {
      long = events.attributes.location.data.attributes.longitude
    }
    return long
  }

  markerArray = [
    {icon : locationMarkerRed, colorCode : "#D62339"},
    {icon : locationMarkerDarkBlue, colorCode : "#043C61"},
    {icon : locationMarkerOrange, colorCode : "#F47133"},
    {icon : locationMarkerPink, colorCode : "#BC3081"},
    {icon : locationMarkerLightGreen, colorCode : "#3C8D9E"},  
  ];

  initialEventInfo: EventInfo = {
    eventTitle : "",
    eventImage : "",
    eventAddress : "",
    eventTags : ""
  }

  handleDynamicMarker = (index : number) => {
    const selectedIndex = this.markerArray[index % this.markerArray.length]
    if(selectedIndex) {
       return selectedIndex.icon
    }
  }

  handleDynamicBgColor = (index : number) => {
    console.log("first", this.state.eventInfo)
    const selectedIndex = this.markerArray[index % this.markerArray.length]
    if(selectedIndex) {
       return selectedIndex.colorCode
    }
  }

  openMapPopUp = (events : IEvent) => {

    if (events && events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes) {
      const locationObj = events.attributes.location.data.attributes
      const eventsData = {
        eventTitle: truthyValue(locationObj.venue_name),
        eventImage: truthyValue(locationObj.image),
        eventAddress: truthyValue(locationObj.address),
        eventTags: ""
      }

      this.setState({
        mapPopUpId: events.id,
        eventInfo: eventsData
      })
    }
   
    
  }

  closeMapPopUp = () => {
    this.setState({
       mapPopUpId : "",
       eventInfo : this.initialEventInfo
    })
  }

  // Customizable Area End
}

// Customizable Area End