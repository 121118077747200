"use client"

import * as React from "react"
import { 
  Dialog, 
  DialogContent, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
  Grid, 
  TextField,
  styled
} from "@mui/material"
import { 
  Close as CloseIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Message as MessageIcon,
  Mail as MailIcon,
  Share as ShareIcon
} from "@mui/icons-material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {formatDate } from "./HelperUtils";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import mailIcon from './mail.svg';
import otherIcon from './others.svg'

interface ShareEventModalProps {
  isOpen: boolean
  onClose: () => void
  event: any
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  opacity: 0.2,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    opacity: 0.3,
  },
}))

const ShareButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  minWidth: 'auto',
}))

export function ShareEventModal({ isOpen, onClose, event }: ShareEventModalProps) {
  const [copied, setCopied] = React.useState(false)
  const shareUrl = "https://www.sparkevents/shakers"

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }
  }

  const shareButtons = [
    {
      name: "Facebook",
      icon: FacebookIcon,
      image : require("./facebook.png"),
      color: "#1877F2",
      bgColor : "#E2E8F0",
      onClick: () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank')
    },
    {
      name: "Instagram",
      icon: InstagramIcon,
      image : require("./instagram.png"),
      color: "#E4405F",
      bgColor : "#F6E1EA",
      onClick: () => window.open(`https://www.instagram.com`, '_blank')
    },
    {
      name: "iMessage",
      icon: MessageIcon,
      image : require("./message.png"),
      color: "#34C759",
      bgColor : "#A4E3A2",
      onClick: () => window.open(`sms:&body=Check out this event: ${shareUrl}`)
    },
    {
      name: "Email",
      icon: MailIcon,
      image :mailIcon ,
      color: "#FF9500",
      bgColor : "#FCE8CE",
      onClick: () => window.open(`mailto:?subject=${event.title}&body=Check out this event: ${shareUrl}`)
    },
    {
      name: "Other",
      icon: ShareIcon,
      color: "#B23AFC",
      image : otherIcon,
      bgColor : "#BC3081",
      onClick: () => navigator.share?.({
        title: event.title,
        text: `Check out this event: ${event.title}`,
        url: shareUrl
      }).catch(console.error)
    }
  ]

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '20px',
        overflow: 'hidden',
        background: "#FFFFFF",
        "& .eventName":{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            marginBottom  :"16px",
            marginTop : "16px"
        },
        "& .eventDetails":{
            fontFamily: 'Inter',
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: '15px',
            display  :"flex",
            alignItems :"center"
        },
        "& .eventIcons" : {
            fontSize : "15px", 
            marginRight : "5px"
        },
        "& .shareEventTxt" : {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            marginBottom : "24px"
        },
        "& .copyBtn" : {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            textTransform : "none",
            backgroundColor: '#3C8D9E',
            color : "white", 
            width : "163px", 
            height : "56px", 
            borderRadius  :"100px",
            alignItems: "center",
            gap : "10px",
            boxShadow: '-12px -12px 24px 0px #055372 inset, 6px 6px 24px 0px #045E8180 inset, 6px 6px 12px 0px #045E8133',
        }
    },
  }))

  const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '14px',
    right: '19px',
    color: '#fff',
    height : "28px",
    width : "28px",
    borderRadius : "50%",
    border : "3px solid #E2E8F0",
    cursor : "pointer"
  });

  return (
    <BootstrapDialog open={isOpen} onClose={onClose} >
      <DialogContent style={{borderRadius : "20px", width : "516px" }} sx={{ padding: "10px"}}>
        <Box sx={{ bgcolor: '#D62339', p: "10px", position: 'relative', borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}>
            <CloseButton onClick={onClose}>
              <CloseRoundedIcon style={{fontSize : "16px"}} />
            </CloseButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              component="img"
              src={event?.event_posters && event?.event_posters[0]?.url}
              alt="Event"
              sx={{ width: 123, height: 123, borderRadius: '50%', objectFit: 'cover', border : "5px solid white" }}
            />
            <Box sx={{ color: 'common.white' }}>
              <Typography className="eventName">
                {event.title}
              </Typography>
              <Typography className="eventDetails">
                <CalendarMonthIcon className="eventIcons" /> 
                {event.start_date && formatDate(event.start_date)} • 
                <AccessTimeIcon className="eventIcons" />
                {event.start_time &&event.end_time
                      ? `${event.start_time} -  ${event.end_time}`
                      : "Time not available"}
              </Typography>
            </Box>
          </Box>
        </Box>
                
        <Box sx={{ p: 3, position : "relative" }}>
        <svg
            width={26}
            height={26}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{position : "absolute", top : "-5px", right : "36px"}}
            >
            <path
                d="M11.7321 17C10.9622 18.3333 9.03775 18.3333 8.26795 17L0.47372 3.5C-0.29608 2.16667 0.666172 0.5 2.20577 0.5L17.7942 0.500001C19.3338 0.500002 20.2961 2.16667 19.5263 3.5L11.7321 17Z"
                fill="#D62339"
            />
            </svg>
          <Typography className="shareEventTxt">
            Share Event
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {shareButtons.map((button) => (
              <Grid item xs={2.4} key={button.name}>
                <ShareButton onClick={button.onClick}>
                  <Box sx={{ bgcolor: button.bgColor, borderRadius: '50%', height : "68px", width : "68px", display : "flex"}}>
                    <img src={button.image} style={{height  :"36px", width : "36px", margin : "auto"}}></img>
                    {/* <button.icon sx={{ color: 'common.white' }} /> */}
                  </Box>
                  <Typography variant="caption" style={{textTransform : "none"}} color="text.secondary">
                    {button.name}
                  </Typography>
                </ShareButton>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ display: 'flex', bgcolor: '#FAF7F7', borderRadius: "100px", height : "75px", alignItems : "center", padding : "12px", boxSizing : "border-box" }}>
            <TextField
              fullWidth
              variant="standard"
              value={shareUrl}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              sx={{
                input: {
                  color: '#888888',
                  paddingLeft : "14px"
                },
              }}
            />
            <Button
              onClick={handleCopyLink}
              className="copyBtn"
            >
                <ContentCopyOutlinedIcon style={{fontSize : "20px"}} />
                <Typography>
                {copied ? "Copied!" : "Copy Link"}
                </Typography>
             
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  )
}

