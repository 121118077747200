export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long', 
        day: '2-digit',  
        month: 'long',   
        year: 'numeric' 
    };
    let updatedDate =new Intl.DateTimeFormat('en-GB', options).format(date).split(' ');
    updatedDate[0]=updatedDate[0]+","
    return updatedDate.join(" ")
  };
  
  export function convertTo24HourFormat(timeString: string): string {
    const [time, modifier] = timeString.split(' '); 
    let [hours, minutes] = time.split(':').map(Number); 
  
    
    if (modifier === 'PM' && hours < 12) hours += 12;  
    if (modifier === 'AM' && hours === 12) hours = 0; 
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };